window.ClubSoda.addToCart = () => {
  const menuChoiceLists = document.querySelectorAll('.menu_choices_list');
  menuChoiceLists.forEach(menuChoiceList => {
    menuChoiceList.addEventListener('change', () => {
      calculateAndUpdateAddToCartButton();
    });
  });
};

calculateAndUpdateAddToCartButton = () => {
  const menuItemPriceElement = document.getElementById('menu-item-price');

  if (menuItemPriceElement === null) {
    return;
  }

  const menuItemOptions = document.getElementById('menu-options');
  const selectedMenuItemOptions =
    Array.from(menuItemOptions.querySelectorAll('input[type="radio"]:checked, input[type="checkbox"]:checked'));
  const sizeOption = selectedMenuItemOptions.find(option => option.name === 'order_item[size_index]');
  const sizePriceIndex = sizeOption?.dataset.priceIndex;
  const additionalOptionPrices = selectedMenuItemOptions.map(option => {
    if (sizePriceIndex && option.dataset[`price${sizePriceIndex}`]) {
      return option.dataset[`price${sizePriceIndex}`];
    }
    return option.dataset.price || 0;
  });
  const menuItemPrice = menuItemPriceElement.dataset.price;
  // If there is a sizeOption then the "base" item price will come from the selected size's price option.
  // Hence, we set the base price to 0 here, if there is a size option in the additionalOptionPrices array.
  const baseMenuItemPrice = sizeOption ? 0 : menuItemPrice;
  const totalPrice = (
    (parseFloat(baseMenuItemPrice) || 0) + additionalOptionPrices.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  ).toFixed(2);
  const priceForQuantity = (totalPrice * document.querySelector('.quantity-input').value).toFixed(2);

  updateButtonText(priceForQuantity);
};

updateButtonText = (totalPrice) => {
  const addToCartButton = document.getElementById('add-to-cart-button');
  const text = addToCartButton.value;
  const dollarIndex = text.indexOf('$');

  if (dollarIndex === -1) {
    // If no dollar amount yet in the button text, just add the total price to the end of the text.
    return addToCartButton.value = `${text} - $${totalPrice}`;
  }
  const beforeDollar = text.slice(0, dollarIndex + 1);
  return addToCartButton.value = `${beforeDollar}${totalPrice}`;
};

scrollToError = () => {
  const errorContainer = document.getElementsByClassName('callout flash alert')[0];

  if (errorContainer === undefined) {
    return;
  }

  errorContainer.scrollIntoView({ behavior: "smooth" });
};
