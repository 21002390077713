window.ClubSoda.itemQuantity = () => {
  const quantityInput = document.getElementById('order_item_quantity');
  const quantityDecrement = document.getElementsByClassName('quantity-decrement')[0];
  const quantityIncrement = document.getElementsByClassName('quantity-increment')[0];

  if (quantityInput === null) {
    return;
  }

  quantityInput.addEventListener('keyup', () => {
    const shouldDisableDecrementer = quantityInput.value == 1;

    quantityDecrement.disabled = shouldDisableDecrementer;
  });

  quantityDecrement.addEventListener('click', () => {
    quantityInput.value = parseInt(quantityInput.value) - 1;

    if (quantityInput.value == 1) {
      quantityDecrement.disabled = true;
    }
    calculateAndUpdateAddToCartButton();
  });

  quantityIncrement.addEventListener('click', () => {
    quantityInput.value = parseInt(quantityInput.value) + 1;

    if (quantityInput.value > 1) {
      quantityDecrement.disabled = false;
    }
    calculateAndUpdateAddToCartButton();
  });
};
